import React, { useEffect, useState, useContext, useCallback, useRef } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useHistory, NavLink, Link } from 'react-router-dom'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import './home.css'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { handleRedirectInternal, mapData } from '../../../Product/common/components'
import { Box, Button, ListItem, Tab, Tabs, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import Loaders from '../../../Product/components/molecules/Loaders'
import jsonp from 'jsonp'
import AlertContext from '../../../Product/context/alert/alertContext'
import queryString from 'query-string'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import { messageHandler } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.css'
import { EffectCoverflow, Navigation, Autoplay, Pagination } from 'swiper'
import Slider from 'react-slick'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import ReactHtmlParser from 'react-html-parser'
import id from 'date-fns/locale/id'
import Reviews from './review'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import { useFormik } from 'formik'
import { ReactGoogleReviews } from 'react-google-reviews'
import 'react-google-reviews/dist/index.css'
import item1 from '../../../assets/images/BANNER01.png'
import item2 from '../../../assets/images/BANNER02.png'
import item3 from '../../../assets/images/BANNER03.png'
import item4 from '../../../assets/images/BANNER04.png'
import schedulePickupContext from '../../../Product/context/plugin/schedulePickup/schedulePickupContext'
import WorkingHours from '../WokringHours'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `home-auctions-${index}`,
        'aria-controls': `home-auctions-tabpanel-${index}`,
    }
}

const Home = (props) => {
    const { user } = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const productContext = useContext(ProductContext)

    const {
        search_allauctions,
        getAllSearchAuctions,
        search_allproducts,
        getAllSearchProducts,
        productLocalState,
        setState: setLocalState,
        clearResponse_search_details,
    } = productContext
    const { getAllTimings, all_timings } = useContext(schedulePickupContext)
    const { setAlert } = alertContext
    const sessionTimeOutRef = useRef(null)
    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [loading_buynow, setLoadingBuynow] = useState(true)

    const [viewProduct, setViewProduct] = useState([])
    const [buyNowProduct, setBuyNowProduct] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [value, setValue] = useState(0)
    const [inpData, setinpData] = useState('')
    const [filterValues, setFilterValues] = useState([])
    const [totalItems, setTotalItem] = useState(0)
    const [allLocations, setAllLocations] = useState({})

    const [viewAuction, setViewAuction] = useState([])
    const [tabValue, setTabValue] = useState(0)
    const containerRef = useRef(null)
    const [cardWidth, setCardWidth] = useState(0)

    const now = new Date()

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    const [slidesToShow, setSlidesToShow] = useState(4)

    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const bodyClass = document.body.classList[1] == 'primeBids'

    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '9',
        searchbar: '',
        auc_type: '2',
        type: '',
        auction_io: 1,
        auction_state:
            productLocalState.globalLocationFilter == 1
                ? []
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[1]?.trim()]
                : [productLocalState.globalLocation],
        auction_city:
            productLocalState.globalLocationFilter == 1
                ? [productLocalState.globalLocation]
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[0]?.trim()]
                : [],
        lot_images: 1,
    })

    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const params = new URLSearchParams(window.location.search.substring(1))

    const formik = useFormik({
        initialValues: {
            sh_limit: sessionStorage.getItem('sh_limit'),
            page: 1,
            orderby: global?.storeConfigration?.liquidation_filter_update?.value == 1 ? '15' : '',
            location: [],
            sortType:
                global?.storeConfigration?.liquidation_filter_update?.value == 1 ? 2 : undefined,
            state: [],
            city: [],
            auction_city:
                productLocalState.globalLocationFilter == 1
                    ? [productLocalState.globalLocation]
                    : productLocalState.globalLocationFilter == 2
                    ? [productLocalState.globalLocation?.split(',')?.[0]?.trim()]
                    : [],
            category: catg ? [catg] : [],
            contenthead5: [],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            miles: params.get('m') ? params.get('m') : '',
            from: '',
            closed_auction_list: 0,
            view_type: params.get('view') ? params.get('view') : 0,
            auction_io: 1,
            conditionunique: [],
            lotsize: ['1'],
            auction_state:
                productLocalState.globalLocationFilter == 1
                    ? []
                    : productLocalState.globalLocationFilter == 2
                    ? [productLocalState.globalLocation?.split(',')?.[1]?.trim()]
                    : [productLocalState.globalLocation],
            orderby2: global?.storeConfigration?.liquidation_filter_update?.value == 1 ? '' : '6',
            need_bid_increment: 1,
        },
    })

    const [subLotSearch, setSubLotSearch] = useState({
        auc_type: '2',
        page: 1,
        orderby: global?.storeConfigration?.home_pag_liq_2_changes?.value ? '16' : '2',
        lotof: '',
        sh_limit: 30,
        market_status: 'open',
        auction_io: 1,
        from: global?.storeConfigration?.home_pag_liq_2_changes?.value
            ? 'auction_only'
            : global.storeConfigration?.liq_home_page_changes?.value == 1
            ? undefined
            : 'auction_only',
        auction_state:
            productLocalState.globalLocationFilter == 1
                ? []
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[1]?.trim()]
                : [productLocalState.globalLocation],
        auction_city:
            productLocalState.globalLocationFilter == 1
                ? [productLocalState.globalLocation]
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[0]?.trim()]
                : [],
        type: 'active',
        need_bid_increment: 1,
    })

    const [buynowSearch, setBuynowSearch] = useState({
        sh_limit: 16,
        from: 'buynow',
        page: 1,
        orderby: '1',
        auction_io: 1,
        auction_state:
            productLocalState.globalLocationFilter == 1
                ? []
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[1]?.trim()]
                : [productLocalState.globalLocation],
        auction_city:
            productLocalState.globalLocationFilter == 1
                ? [productLocalState.globalLocation]
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[0]?.trim()]
                : [],
    })

    // useEffect(() => {
    //     if (value === 0 || value === 1) {
    //         setSubLotSearch({ ...subLotSearch, type: 'active', auc_type: '2' })
    //     }
    //     if (value === 2) {
    //         setBuynowSearch({ ...buynowSearch, type: 'active' })
    //     }
    // }, [value])

    useEffect(() => {
        if (
            document.body.className == 'liquidationTwo wizardTheme' &&
            global?.storeDetails?.site_id
        ) {
            getAllTimings({
                site_id: global?.storeDetails?.site_id,
                random_id: '',
                id: '',
            })
        }
    }, [global?.storeDetails?.site_id, document.body.className])

    useEffect(() => {
        if (
            all_timings.all_locations.length > 0 &&
            all_timings.all_locations[0].range_key.length > 0
        ) {
            setAllLocations(all_timings.all_locations.map((location) => location.range_key[0]))
        }
    }, [all_timings.all_locations])

    useEffect(() => {
        if (search_allproducts.from === 'activeHome') {
            if (search_allproducts.results.length > 0) {
                search_allproducts.results.map((data) => {
                    if (data?.extra_fields?.includes('}')) {
                        let parse = JSON.parse(data.extra_fields)
                        Object.keys(parse).map((val) => {
                            data[val] = parse[val]
                        })
                    }
                })

                setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            } else {
                setViewProduct([])
            }
            setLoading(false)
            setTotalItem(search_allproducts.total_pagecnt)
            clearTimeout(sessionTimeOutRef.current)
            clearResponse_search_details()
        } else if (search_allproducts.from === 'galleryPage') {
            if (search_allproducts.results.length > 0) {
                search_allproducts.results.map((data) => {
                    if (data?.extra_fields?.includes('}')) {
                        let parse = JSON.parse(data.extra_fields)
                        Object.keys(parse).map((val) => {
                            data[val] = parse[val]
                        })
                    }
                })
                setBuyNowProduct(
                    search_allproducts.results.length ? search_allproducts.results : [],
                )
            } else {
                setBuyNowProduct([])
            }
            setLoadingBuynow(false)
            setTotalItem(search_allproducts.total_pagecnt)
            clearResponse_search_details()
        }
    }, [search_allproducts])

    useEffect(() => {
        setLoadingBuynow(true)
        if (value === 2) {
            getAllSearchProducts(buynowSearch, 'galleryPage')
        }
    }, [buynowSearch])

    useEffect(() => {
        setLoading(true)
        getAllSearchProducts(subLotSearch, 'activeHome')
        sessionTimeOutRef.current = setTimeout(() => {
            if (!window.location.pathname.includes('/search')) {
                getAllSearchProducts(subLotSearch, 'activeHome')
            }
        }, 8000)
    }, [subLotSearch, isAuthenticated])

    useEffect(() => {
        if (productLocalState.globalLocationFilter == 1) {
            if (subLotSearch.auction_city != productLocalState.globalLocation) {
                setSubLotSearch({
                    ...subLotSearch,
                    auction_city: [productLocalState.globalLocation],
                })
                setBuynowSearch({
                    ...buynowSearch,
                    auction_city: [productLocalState.globalLocation],
                })
                setAuctionSearch({
                    ...auctionSearch,
                    auction_city: [productLocalState.globalLocation],
                })
            }
        } else if (productLocalState.globalLocationFilter == 2) {
            let city = productLocalState.globalLocation?.split(',')?.[0]?.trim()
            let state = productLocalState.globalLocation?.split(',')?.[1]?.trim()
            if (subLotSearch.auction_city != city || subLotSearch.auction_state != state) {
                setSubLotSearch({
                    ...subLotSearch,
                    auction_city: city,
                    auction_state: state,
                })
                setBuynowSearch({
                    ...buynowSearch,
                    auction_city: city,
                    auction_state: state,
                })
                setAuctionSearch({
                    ...auctionSearch,
                    auction_city: city,
                    auction_state: state,
                })
            }
        } else {
            if (subLotSearch.auction_state != productLocalState.globalLocation) {
                setSubLotSearch({
                    ...subLotSearch,
                    auction_state: [productLocalState.globalLocation],
                })
                setBuynowSearch({
                    ...buynowSearch,
                    auction_state: [productLocalState.globalLocation],
                })
                setAuctionSearch({
                    ...auctionSearch,
                    auction_state: [productLocalState.globalLocation],
                })
            }
        }
    }, [productLocalState.globalLocation])
    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socket.on('buyer_assurance', (data) => {
            handler(data, 'buyer_assurance')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('buyer_assurance', (data) => {
                handler(data, 'buyer_assurance')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    useEffect(() => {
        setViewProduct([])
        setBuyNowProduct([])
        return () => {
            setViewProduct([])
            setBuyNowProduct([])
        }
    }, [])

    useEffect(() => {
        setFilterValues(
            global?.allCategories
                ?.filter((val) => val.level === 0)
                ?.sort((a, b) => a.description.localeCompare(b.description)),
        )
    }, [global.allCategories])

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }
    const handleChange = (event, newValue) => {
        setValue(newValue)
        if (newValue === 0 || newValue === 1) {
            if (global?.storeConfigration?.home_pag_liq_2_changes?.value) {
                setSubLotSearch({
                    ...subLotSearch,
                    type: 'active',
                    auc_type: '2',
                    most_viewed: undefined,
                    viewers: undefined,
                    featured: undefined,
                    orderby: newValue == 0 ? '11' : '',
                })
            } else {
                setSubLotSearch({
                    ...subLotSearch,
                    type: 'active',
                    auc_type: '2',
                    most_viewed: undefined,
                    viewers: undefined,
                    featured: undefined,
                })
            }

            //getAllSearchProducts({ ...subLotSearch, type: 'active', auc_type: '2' }, 'activeHome')
        }
        if (newValue === 3) {
            setSubLotSearch({
                ...subLotSearch,
                type: 'active',
                auc_type: '2',
                featured: 1,
                most_viewed: undefined,
                viewers: undefined,
            })
            //getAllSearchProducts({ ...subLotSearch, type: 'active', auc_type: '2' }, 'activeHome')
        }
        if (newValue === 4) {
            setSubLotSearch({
                ...subLotSearch,
                type: 'active',
                auc_type: '2',
                most_viewed: 1,
                viewers: 1,
                featured: undefined,
            })
            //getAllSearchProducts({ ...subLotSearch, type: 'active', auc_type: '2' }, 'activeHome')
        }
        if (newValue === 2) {
            if (global?.storeConfigration?.home_pag_liq_2_changes?.value) {
                handleRedirectInternal(history, 'how_it_works')
            } else {
                setBuynowSearch({ ...buynowSearch, type: 'active' })
                //getAllSearchProducts({ ...buynowSearch, type: 'active' }, 'galleryPage')
            }
        }
    }

    const [state, setState] = React.useState({
        bottom: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'home_page')
    }, [auctionSearch])

    useEffect(() => {
        // if (search_allauctions.from == 'home_page') {
        setViewAuction(search_allauctions?.results?.length ? search_allauctions?.results : [])
        // } else {
        //     setViewAuction([])
        // }
    }, [search_allauctions])

    useEffect(() => {
        const updateSlides = () => {
            const width = window.innerWidth
            const cols = Math.max(1, Math.floor(width / 300))
            setSlidesToShow(cols)
        }

        updateSlides()
        window.addEventListener('resize', updateSlides)
        return () => window.removeEventListener('resize', updateSlides)
    }, [])

    const subscribe = () => {
        if (inpData) {
            if (!/\S+@\S+\.\S+/.test(inpData)) {
                return setAlert('Invalid email address', 'error')
            }

            const data = {
                EMAIL: inpData,
            }

            jsonp(
                `//${global.storeConfigration?.subscribe_code.value}${queryString.stringify(data)}`,
                { param: 'c' },
                (err, data) => {
                    //console.log('display', data.result)
                    if (err) {
                        return false
                    }
                    if (data.result === 'success') {
                        setinpData('')
                        return setAlert(data.msg, 'success')
                    }
                    if (data.result === 'error') {
                        return setAlert(data.msg, 'warning')
                    }
                },
            )
        }
        setinpData('')
    }

    const NextArrow = (props) => {
        const { onClick } = props
        return (
            <Button className="custom-arrow next" onClick={onClick}>
                <span className="material-icons-outlined">arrow_forward_ios</span>
            </Button>
        )
    }

    const PrevArrow = (props) => {
        const { onClick } = props
        return (
            <Button className="custom-arrow prev" onClick={onClick}>
                <span className="material-icons-outlined">arrow_back_ios</span>
            </Button>
        )
    }

    const settings = {
        dots: false,
        fade: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 10000,
        cssEase: 'linear',
    }

    const settingsWizard = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToScroll: 4,
        slidesToShow: slidesToShow,
        variableWidth: false,
        adaptiveHeight: false,
        swipeToSlide: true,
        touchMove: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    const settingsEmurs = {
        dots: false,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        fade: true,
        autoplaySpeed: 10000,
        cssEase: 'linear',
    }
    const categoriesSettings = {
        dots: false,
        fade: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,

        autoplay: true,
        speed: 2000,
        autoplaySpeed: 6000,
        cssEase: 'linear',
    }
    const checkThemeClassName = document.body.className == 'liquidationTwo primeBids'
    const checkEmursTheme = document.body.className == 'liquidationTwo emursMck'
    const LiquidityProject = document.body.className == 'liquidationTwo liquidity'
    const checkBeachThemeClassName = document.body.className == 'liquidationTwo beachLiquidation'
    const checkMediaWithPrimeBidTheme =
        window.matchMedia('(max-width:768px)').matches && checkThemeClassName
    const isWizardTheme = document.body.classList[1] == 'wizardTheme'

    useEffect(() => {
        if (checkEmursTheme) {
            setLocalState((prevState) => ({
                ...prevState,
                globalLocation: 'New York',
                globalLocations: ['New York'],
                globalLocationFilter: 2,
            }))
            localStorage.setItem('globalLocation', JSON.stringify('New York'))
        }
        if (localStorage.getItem('globalLocation') == 'California' || checkEmursTheme) {
            localStorage.setItem('globalLocation', 'New York')
        }
    }, [search_allproducts, subLotSearch])

    const imgPath = [item1, item2, item3, item4]

    const calculateCardWidth = () => {
        const firstCard = containerRef.current?.children[0]
        if (firstCard) {
            setCardWidth(firstCard.offsetWidth)
        }
    }

    useEffect(() => {
        calculateCardWidth()
        window.addEventListener('resize', calculateCardWidth)

        return () => {
            window.removeEventListener('resize', calculateCardWidth)
        }
    }, [])

    return (
        <Layout props={props}>
            <div className="home">
                <>
                    {checkEmursTheme ? (
                        <div className="customContainer seperator bannerContainer">
                            <div className="siteBanner multiple">
                                <Slider {...settingsEmurs}>
                                    {global.multipleBannersFields?.map((data, index) => (
                                        <>
                                            <img
                                                src={
                                                    process.env.REACT_APP_AUCTION_IMAGE_URL +
                                                    '/' +
                                                    data?.image_name
                                                }
                                            />
                                            <div className="bannerContent">
                                                <h2 className="">
                                                    {
                                                        JSON.parse(data.fields ? data.fields : '{}')
                                                            .title
                                                    }
                                                </h2>

                                                {checkEmursTheme && (
                                                    <>
                                                        <h2>{`The McKittrick Hotel`}</h2>
                                                        <h3>{`Scenery & Props Auction`}</h3>
                                                        {/* <h2>DRAMA IS OVER</h2> 
                                                        <h3>
                                                            LETS BID NOW{' '}
                                                            {/* <span>{subLotSearch.auction_city}</span> 
                                                        </h3> */}
                                                        <div className="text-center">
                                                            {!isAuthenticated ? (
                                                                <PrimaryButton
                                                                    label={
                                                                        isAuthenticated
                                                                            ? JSON.parse(
                                                                                  data.fields
                                                                                      ? data.fields
                                                                                      : '{}',
                                                                              ).btn_text
                                                                            : 'SIGNUP'
                                                                    }
                                                                    type="button"
                                                                    onClick={() =>
                                                                        !isAuthenticated
                                                                            ? handleRedirectInternal(
                                                                                  history,
                                                                                  'sign-up',
                                                                              )
                                                                            : (window.location.href =
                                                                                  JSON.parse(
                                                                                      global
                                                                                          .multipleBannersFields[
                                                                                          index
                                                                                      ].fields
                                                                                          ? global
                                                                                                .multipleBannersFields[
                                                                                                index
                                                                                            ].fields
                                                                                          : '{}',
                                                                                  ).btn_link)
                                                                    }
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    ) : null}
                    {!isWizardTheme ? (
                        checkEmursTheme ? null : (
                            <div className="customContainer bannerContainer">
                                {global.multipleBannersFields.length ? (
                                    <div className="siteBanner multiple">
                                        {
                                            <Slider {...settings}>
                                                {global.multipleBannersFields?.map(
                                                    (data, index) => (
                                                        <>
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_AUCTION_IMAGE_URL +
                                                                    '/' +
                                                                    data?.image_name
                                                                }
                                                            />
                                                            <div className="bannerContent">
                                                                <h2 className="">
                                                                    {
                                                                        JSON.parse(
                                                                            data.fields
                                                                                ? data.fields
                                                                                : '{}',
                                                                        ).title
                                                                    }
                                                                </h2>

                                                                {checkThemeClassName && (
                                                                    <>
                                                                        {/* <h2 className="">
                                                                    {
                                                                        JSON.parse(
                                                                            data.fields
                                                                                ? data.fields
                                                                                : '{}',
                                                                        ).description
                                                                    }
                                                                </h2> */}
                                                                        <h3>
                                                                            Bid, Buy, and Enjoy!
                                                                        </h3>
                                                                        <h2>
                                                                            Shop & <br />
                                                                            Save in{' '}
                                                                            <span>
                                                                                {
                                                                                    subLotSearch.auction_city
                                                                                }
                                                                            </span>
                                                                            .
                                                                        </h2>

                                                                        <PrimaryButton
                                                                            label={
                                                                                isAuthenticated
                                                                                    ? JSON.parse(
                                                                                          data.fields
                                                                                              ? data.fields
                                                                                              : '{}',
                                                                                      ).btn_text
                                                                                    : 'Create your free account'
                                                                            }
                                                                            type="button"
                                                                            onClick={() =>
                                                                                !isAuthenticated
                                                                                    ? handleRedirectInternal(
                                                                                          history,
                                                                                          'sign-up',
                                                                                      )
                                                                                    : (window.location.href =
                                                                                          JSON.parse(
                                                                                              global
                                                                                                  .multipleBannersFields[
                                                                                                  index
                                                                                              ]
                                                                                                  .fields
                                                                                                  ? global
                                                                                                        .multipleBannersFields[
                                                                                                        index
                                                                                                    ]
                                                                                                        .fields
                                                                                                  : '{}',
                                                                                          ).btn_link)
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                        </>
                                                    ),
                                                )}
                                            </Slider>
                                        }
                                    </div>
                                ) : (
                                    <div className="siteBanner">
                                        <LazyLoadImage
                                            src={global?.storeDetails?.bannerValue}
                                            alt={`${global?.storeDetails?.name} banner`}
                                            effect="blur"
                                            height="100%"
                                            width="100%"
                                        />
                                        <div className="bannerContent">
                                            <h2>{global?.storeDetails?.description}</h2>
                                        </div>
                                    </div>
                                )}
                                {checkThemeClassName || checkEmursTheme ? (
                                    ''
                                ) : (
                                    <div>
                                        <h3 className="todayDealsTit">Today&apos;s Deals</h3>
                                        <div className="PmCategoryBase-root">
                                            {console.log(filterValues, 'filterValues')}

                                            {filterValues?.map((data, index) => (
                                                <div
                                                    className="PmCategoryList-root"
                                                    key={index}
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'search?q=&z=&m=&view=0&cat=' + data.id,
                                                        )
                                                    }
                                                >
                                                    <picture className="PmCategoryImage-root">
                                                        <img
                                                            className="PmCategoryImage lazy"
                                                            src={
                                                                data.image != 'noimg'
                                                                    ? process.env
                                                                          .REACT_APP_PRODUCT_IMAGE_URL +
                                                                      data.image
                                                                    : process.env
                                                                          .REACT_APP_AUCTION_IMAGE_URL +
                                                                      global?.storeDetails?.logo
                                                            }
                                                            data-src="image.jpg"
                                                            alt={data.description}
                                                        />
                                                    </picture>
                                                    <h4 className="PmCategoryTitle m-0">
                                                        {data.description}
                                                    </h4>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    ) : (
                        <div className="customContainer bannerContainer">
                            <div className={`siteBanner ${isAuthenticated ? 'login' : ''}`}>
                                {isAuthenticated ? (
                                    <div className="wizardSi">
                                        <div>
                                            <div className="bannerContent d-flex align-items-center justify-content-around mb-2">
                                                <div className="bannetContentWrp">
                                                    <h2>{global?.storeDetails?.description}</h2>
                                                    <Button className="lgSgnBtn">
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/favorites"
                                                            className="signInButton"
                                                        >
                                                            Your Favorites{' '}
                                                        </NavLink>

                                                        <span className="material-icons">
                                                            verified
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="howItCard text-center">
                                                <h2 className="mb-3">How it works ?</h2>
                                                <div className="hiw-container">
                                                    <div className="hiw d-flex align-items-center">
                                                        <img src="/assets/images/wizardAuctions/BidImg.svg" />
                                                        <div>
                                                            <h3>BID</h3>
                                                            <p>
                                                                Free to sign up, simply browse, bid,
                                                                and let the magic unfold.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="hiw d-flex align-items-center">
                                                        <img src="/assets/images/wizardAuctions/WinImg.svg" />
                                                        <div>
                                                            <h3>WIN</h3>
                                                            <p>
                                                                Saving has never felt so enchanting.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="hiw d-flex align-items-center">
                                                        <img src="/assets/images/wizardAuctions/PickUpImg.png" />
                                                        <div>
                                                            <h3>PICK UP</h3>
                                                            <p>
                                                                Collect your treasures within 5 days
                                                                of the auction closing.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wizardCards">
                                            {viewAuction.length ? (
                                                <>
                                                    {viewAuction
                                                        ?.filter(
                                                            (ele) =>
                                                                new Date(ele.date_closed) >= now,
                                                        )
                                                        ?.sort(
                                                            (a, b) =>
                                                                new Date(a.date_closed) -
                                                                new Date(b.date_closed),
                                                        )
                                                        ?.slice(0, 1)
                                                        .map((data, index) => (
                                                            <MultisellerListCard
                                                                data={data}
                                                                key={index}
                                                                details={`search/product-buyer-auction/${data.id}`}
                                                                card_theme="liquidationTwo"
                                                                handleLotClick={handleClick}
                                                            />
                                                        ))}
                                                </>
                                            ) : null}

                                            {viewProduct
                                                ?.filter(
                                                    (ele) =>
                                                        ele.auction === 1 &&
                                                        new Date(ele.date_closed) >= now,
                                                )
                                                ?.sort(
                                                    (a, b) =>
                                                        new Date(a.date_closed) -
                                                        new Date(b.date_closed),
                                                )
                                                ?.slice(0, 1)
                                                ?.map((data, index) => {
                                                    return (
                                                        <>
                                                            {!checkMediaWithPrimeBidTheme ? (
                                                                <div className="homeGridCard">
                                                                    <GridProduct
                                                                        data={data}
                                                                        className={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        key={index}
                                                                        type={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        selectedProduct={
                                                                            selectedProduct
                                                                        }
                                                                        link={`/gallery/product-view/${data.id}`}
                                                                        handleClick={handleClick}
                                                                        timerTheme=""
                                                                        sliderTheme="multiseller"
                                                                        cardTheme="liquidationTwo"
                                                                        bidBtnLabel="Bid Now"
                                                                        showCondition={false}
                                                                        showLocation={true}
                                                                        timerIcon={true}
                                                                        bidStatus={true}
                                                                        setViewProduct={
                                                                            setViewProduct
                                                                        }
                                                                        viewProduct={viewProduct}
                                                                        showDateNumber={2}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div className="lotResults mb-4 liquidationBuyCard">
                                                                    <GridProduct
                                                                        data={data}
                                                                        className={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        key={index}
                                                                        type={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        selectedProduct={
                                                                            selectedProduct
                                                                        }
                                                                        link={`/gallery/product-view/${data.id}`}
                                                                        handleClick={handleClick}
                                                                        timerTheme=""
                                                                        sliderTheme="multiseller"
                                                                        cardTheme="liquidationTwo"
                                                                        bidBtnLabel="Place Bid"
                                                                        showCondition={false}
                                                                        showLocation={true}
                                                                        timerIcon={true}
                                                                        bidStatus={true}
                                                                        setViewProduct={
                                                                            setViewProduct
                                                                        }
                                                                        viewProduct={viewProduct}
                                                                        showDateNumber={2}
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="bannerContent d-flex align-items-center justify-content-around mb-2">
                                            <div>
                                                <h2>{global?.storeDetails?.description}</h2>
                                                <Button className="lgSgnBtn">
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/login"
                                                        className="signInButton"
                                                    >
                                                        Join Now
                                                    </NavLink>
                                                </Button>
                                            </div>
                                            <LazyLoadImage
                                                src={global?.storeDetails?.bannerValue}
                                                alt={`${global?.storeDetails?.name} banner`}
                                                effect="blur"
                                                height="100%"
                                                width="100%"
                                            />
                                        </div>
                                        <div className="wizardCards">
                                            {viewAuction.length ? (
                                                <>
                                                    {viewAuction
                                                        ?.filter(
                                                            (ele) =>
                                                                new Date(ele.date_closed) >= now,
                                                        )
                                                        ?.sort(
                                                            (a, b) =>
                                                                new Date(a.date_closed) -
                                                                new Date(b.date_closed),
                                                        )
                                                        ?.slice(0, 1)
                                                        .map((data, index) => (
                                                            <MultisellerListCard
                                                                data={data}
                                                                key={index}
                                                                details={`search/product-buyer-auction/${data.id}`}
                                                                card_theme="liquidationTwo"
                                                                handleLotClick={handleClick}
                                                            />
                                                        ))}
                                                </>
                                            ) : null}

                                            {viewProduct
                                                ?.filter(
                                                    (ele) =>
                                                        ele.auction === 1 &&
                                                        new Date(ele.date_closed) >= now,
                                                )
                                                ?.sort(
                                                    (a, b) =>
                                                        new Date(a.date_closed) -
                                                        new Date(b.date_closed),
                                                )
                                                ?.slice(0, 1)
                                                ?.map((data, index) => {
                                                    return (
                                                        <>
                                                            {!checkMediaWithPrimeBidTheme ? (
                                                                <div className="homeGridCard">
                                                                    <GridProduct
                                                                        data={data}
                                                                        className={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        key={index}
                                                                        type={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        selectedProduct={
                                                                            selectedProduct
                                                                        }
                                                                        link={`/gallery/product-view/${data.id}`}
                                                                        handleClick={handleClick}
                                                                        timerTheme=""
                                                                        sliderTheme="multiseller"
                                                                        cardTheme="liquidationTwo"
                                                                        bidBtnLabel="Bid Now"
                                                                        showCondition={false}
                                                                        showLocation={true}
                                                                        timerIcon={true}
                                                                        bidStatus={true}
                                                                        setViewProduct={
                                                                            setViewProduct
                                                                        }
                                                                        viewProduct={viewProduct}
                                                                        showDateNumber={2}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div className="lotResults mb-4 liquidationBuyCard">
                                                                    <GridProduct
                                                                        data={data}
                                                                        className={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        key={index}
                                                                        type={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        selectedProduct={
                                                                            selectedProduct
                                                                        }
                                                                        link={`/gallery/product-view/${data.id}`}
                                                                        handleClick={handleClick}
                                                                        timerTheme=""
                                                                        sliderTheme="multiseller"
                                                                        cardTheme="liquidationTwo"
                                                                        bidBtnLabel="Place Bid"
                                                                        showCondition={false}
                                                                        showLocation={true}
                                                                        timerIcon={true}
                                                                        bidStatus={true}
                                                                        setViewProduct={
                                                                            setViewProduct
                                                                        }
                                                                        viewProduct={viewProduct}
                                                                        showDateNumber={2}
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                })}

                                            <div className="howItCard text-center">
                                                <h2 className="mb-3">How it works ?</h2>
                                                <div className="d-flex align-items-center mb-1">
                                                    <img src="/assets/images/wizardAuctions/BidImg.svg" />
                                                    <div>
                                                        <h3>BID</h3>
                                                        <p>
                                                            Free to sign up, simply browse, bid, and
                                                            let the magic unfold.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-1 flex-row-reverse">
                                                    <img src="/assets/images/wizardAuctions/WinImg.svg" />
                                                    <div>
                                                        <h3>WIN</h3>
                                                        <p>Saving has never felt so enchanting.</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <img src="/assets/images/wizardAuctions/PickUpImg.png" />
                                                    <div>
                                                        <h3>PICK UP</h3>
                                                        <p>
                                                            Collect your treasures within 5 days of
                                                            the auction closing.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="howItCard justify-content-start text-center">
                                                <img
                                                    className="wizardImg"
                                                    src="/assets/images/wizardAuctions/WizardImg.png"
                                                />
                                                <p className="quest">
                                                    Your quest to Magical Savings Begins Here!
                                                </p>
                                                <Button
                                                    className="lgSgnBtn mt-2"
                                                    style={{ maxWidth: '165px', margin: 'auto' }}
                                                >
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/login"
                                                        className="signInButton"
                                                    >
                                                        Sign Up
                                                    </NavLink>
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    {checkThemeClassName || checkEmursTheme ? (
                        ''
                    ) : (
                        <div className="customContainer">
                            <h3 className="todayDealsTit ">Category</h3>
                            <div className="PmCategoryBase-root categories position-relative">
                                {console.log(filterValues, 'filterValues')}
                                {filterValues
                                    ?.filter((filterItem) =>
                                        global.searchCategories.some(
                                            (category) =>
                                                filterItem.description === category.description,
                                        ),
                                    )
                                    ?.map((data, index) => {
                                        const categoryToUpdate = global.searchCategories.find(
                                            (category) => category.description === data.description,
                                        )

                                        const updatedData = categoryToUpdate
                                            ? { ...data, id: categoryToUpdate.id }
                                            : data

                                        return (
                                            <div
                                                className="PmCategoryList-root"
                                                key={index}
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        'search?q=&z=&m=&view=0&cat=' +
                                                            updatedData.id,
                                                    )
                                                }
                                            >
                                                <picture className="PmCategoryImage-root">
                                                    <img
                                                        className="PmCategoryImage lazy"
                                                        src={
                                                            updatedData.image != 'noimg'
                                                                ? process.env
                                                                      .REACT_APP_PRODUCT_IMAGE_URL +
                                                                  updatedData.image
                                                                : process.env
                                                                      .REACT_APP_AUCTION_IMAGE_URL +
                                                                  global?.storeDetails?.logo
                                                        }
                                                        data-src="image.jpg"
                                                        alt={updatedData.description}
                                                    />
                                                </picture>
                                                <h4 className="PmCategoryTitle m-0">
                                                    {updatedData.description}
                                                </h4>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    )}
                    <div className="customContainer auctionListCnt">
                        {!isWizardTheme ? (
                            <>
                                {global?.storeConfigration?.homeTrendingView?.value == 1 ? (
                                    <div className="searchHomeContainer">
                                        {global.storeConfigration?.liq_home_page_changes?.value ==
                                        1 ? (
                                            <>
                                                {viewProduct?.map((data, index) => {
                                                    if (index < 15) {
                                                        return (
                                                            <div className="homeGridCard">
                                                                <GridProduct
                                                                    data={data}
                                                                    className={
                                                                        data.auction
                                                                            ? 'auction'
                                                                            : 'buynow'
                                                                    }
                                                                    key={index}
                                                                    type={
                                                                        data.auction
                                                                            ? 'auction'
                                                                            : 'buynow'
                                                                    }
                                                                    selectedProduct={
                                                                        selectedProduct
                                                                    }
                                                                    link={`/gallery/product-view/${data.id}`}
                                                                    handleClick={handleClick}
                                                                    timerTheme=""
                                                                    sliderTheme="multiseller"
                                                                    cardTheme="liquidationTwo"
                                                                    bidBtnLabel="Place Bid"
                                                                    showCondition={false}
                                                                    showLocation={true}
                                                                    timerIcon={true}
                                                                    bidStatus={true}
                                                                    setViewProduct={setViewProduct}
                                                                    viewProduct={viewProduct}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                {viewProduct
                                                    ?.filter(
                                                        (ele) =>
                                                            ele.auction == 1 && ele.buynow == 1,
                                                    )
                                                    ?.map((data, index) => {
                                                        if (index < 15) {
                                                            return (
                                                                <div className="homeGridCard asdasd">
                                                                    <GridProduct
                                                                        data={data}
                                                                        className={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        key={index}
                                                                        type={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        selectedProduct={
                                                                            selectedProduct
                                                                        }
                                                                        link={`/gallery/product-view/${data.id}`}
                                                                        handleClick={handleClick}
                                                                        timerTheme=""
                                                                        sliderTheme="multiseller"
                                                                        cardTheme="liquidationTwo"
                                                                        bidBtnLabel="Bid Now"
                                                                        showCondition={false}
                                                                        showLocation={true}
                                                                        timerIcon={true}
                                                                        bidStatus={true}
                                                                        setViewProduct={
                                                                            setViewProduct
                                                                        }
                                                                        viewProduct={viewProduct}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div className={checkEmursTheme ? 'seperator' : null}>
                                        {(checkThemeClassName || checkEmursTheme) && (
                                            <div className="pmEllipseContainer d-flex align-items-center pt-5 pb-5">
                                                <div className="PmEllipse">
                                                    <span className="material-icons-outlined">
                                                        trending_up
                                                    </span>
                                                </div>
                                                <h3>Trending Now in {subLotSearch.auction_city}</h3>
                                            </div>
                                        )}

                                        <div className="tabHeader">
                                            <Tabs value={tabValue}>
                                                <Tab label="Active Lots" {...a11yProps(0)} />
                                            </Tabs>
                                        </div>

                                        {checkThemeClassName || checkEmursTheme ? (
                                            <>
                                                <TabPanel value={tabValue} index={0}>
                                                    <div className="homeCnt">
                                                        <>
                                                            {loading ? (
                                                                <div className="homeLoader">
                                                                    <Loaders
                                                                        name="product_grid_view"
                                                                        isLoading={loading}
                                                                        loop={5}
                                                                    />
                                                                </div>
                                                            ) : viewProduct.length === 0 ? (
                                                                <NoRecordsFound />
                                                            ) : (
                                                                <div>
                                                                    <div
                                                                        className={
                                                                            'searchHomeContainer'
                                                                        }
                                                                    >
                                                                        {viewProduct
                                                                            ?.filter(
                                                                                (ele) =>
                                                                                    ele.auction ==
                                                                                    1,
                                                                            )
                                                                            ?.map((data, index) => {
                                                                                if (index < 30) {
                                                                                    return (
                                                                                        <>
                                                                                            {!checkMediaWithPrimeBidTheme ? (
                                                                                                <div className="homeGridCard">
                                                                                                    <GridProduct
                                                                                                        data={
                                                                                                            data
                                                                                                        }
                                                                                                        className={
                                                                                                            data.auction
                                                                                                                ? 'auction'
                                                                                                                : 'buynow'
                                                                                                        }
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                        type={
                                                                                                            data.auction
                                                                                                                ? 'auction'
                                                                                                                : 'buynow'
                                                                                                        }
                                                                                                        selectedProduct={
                                                                                                            selectedProduct
                                                                                                        }
                                                                                                        link={`/gallery/product-view/${data.id}`}
                                                                                                        handleClick={
                                                                                                            handleClick
                                                                                                        }
                                                                                                        timerTheme=""
                                                                                                        sliderTheme="multiseller"
                                                                                                        cardTheme="liquidationTwo"
                                                                                                        bidBtnLabel="Place Bid"
                                                                                                        showCondition={
                                                                                                            false
                                                                                                        }
                                                                                                        showLocation={
                                                                                                            true
                                                                                                        }
                                                                                                        timerIcon={
                                                                                                            true
                                                                                                        }
                                                                                                        bidStatus={
                                                                                                            true
                                                                                                        }
                                                                                                        setViewProduct={
                                                                                                            setViewProduct
                                                                                                        }
                                                                                                        viewProduct={
                                                                                                            viewProduct
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className="lotResults mb-4 liquidationBuyCard">
                                                                                                    <GridProduct
                                                                                                        data={
                                                                                                            data
                                                                                                        }
                                                                                                        className={
                                                                                                            data.auction
                                                                                                                ? 'auction'
                                                                                                                : 'buynow'
                                                                                                        }
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                        type={
                                                                                                            data.auction
                                                                                                                ? 'auction'
                                                                                                                : 'buynow'
                                                                                                        }
                                                                                                        selectedProduct={
                                                                                                            selectedProduct
                                                                                                        }
                                                                                                        link={`/gallery/product-view/${data.id}`}
                                                                                                        handleClick={
                                                                                                            handleClick
                                                                                                        }
                                                                                                        timerTheme=""
                                                                                                        sliderTheme="multiseller"
                                                                                                        cardTheme="liquidationTwo"
                                                                                                        bidBtnLabel="Place Bid"
                                                                                                        showCondition={
                                                                                                            false
                                                                                                        }
                                                                                                        showLocation={
                                                                                                            true
                                                                                                        }
                                                                                                        timerIcon={
                                                                                                            true
                                                                                                        }
                                                                                                        bidStatus={
                                                                                                            true
                                                                                                        }
                                                                                                        setViewProduct={
                                                                                                            setViewProduct
                                                                                                        }
                                                                                                        viewProduct={
                                                                                                            viewProduct
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    </div>
                                                </TabPanel>
                                                <div className="d-flex justify-content-end pt-2">
                                                    <NavLink
                                                        activeClassName="active"
                                                        className="ctLink"
                                                        to="/search"
                                                    >
                                                        View all trending
                                                        {checkEmursTheme ? (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="31"
                                                                height="30"
                                                                viewBox="0 0 31 30"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M16.8238 8.49582C17.0816 8.23802 17.4996 8.23802 17.7574 8.49582L23.7325 14.4709C23.9903 14.7287 23.9903 15.1467 23.7325 15.4045L17.7574 21.3796C17.4996 21.6374 17.0816 21.6374 16.8238 21.3796C16.566 21.1217 16.566 20.7038 16.8238 20.4459L21.6719 15.5979H8.32806C7.96346 15.5979 7.6679 15.3023 7.6679 14.9377C7.6679 14.5731 7.96346 14.2775 8.32806 14.2775L21.6719 14.2775L16.8238 9.42943C16.566 9.17162 16.566 8.75363 16.8238 8.49582Z"
                                                                    fill="#ffffff"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="31"
                                                                height="30"
                                                                viewBox="0 0 31 30"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M16.8238 8.49582C17.0816 8.23802 17.4996 8.23802 17.7574 8.49582L23.7325 14.4709C23.9903 14.7287 23.9903 15.1467 23.7325 15.4045L17.7574 21.3796C17.4996 21.6374 17.0816 21.6374 16.8238 21.3796C16.566 21.1217 16.566 20.7038 16.8238 20.4459L21.6719 15.5979H8.32806C7.96346 15.5979 7.6679 15.3023 7.6679 14.9377C7.6679 14.5731 7.96346 14.2775 8.32806 14.2775L21.6719 14.2775L16.8238 9.42943C16.566 9.17162 16.566 8.75363 16.8238 8.49582Z"
                                                                    fill="#222222"
                                                                />
                                                            </svg>
                                                        )}
                                                    </NavLink>{' '}
                                                </div>
                                            </>
                                        ) : (
                                            <div
                                                className={
                                                    checkMediaWithPrimeBidTheme || checkEmursTheme
                                                        ? 'searchContainer gridViewPrimebidsMobile'
                                                        : 'searchHomeContainer gf'
                                                }
                                            >
                                                {viewProduct
                                                    ?.filter((ele) => ele.auction == 1)
                                                    ?.map((data, index) => {
                                                        if (index < 28) {
                                                            return (
                                                                <>
                                                                    {!checkMediaWithPrimeBidTheme ||
                                                                    checkEmursTheme ? (
                                                                        <div className="homeGridCard">
                                                                            <GridProduct
                                                                                data={data}
                                                                                className={
                                                                                    data.auction
                                                                                        ? 'auction'
                                                                                        : 'buynow'
                                                                                }
                                                                                key={index}
                                                                                type={
                                                                                    data.auction
                                                                                        ? 'auction'
                                                                                        : 'buynow'
                                                                                }
                                                                                selectedProduct={
                                                                                    selectedProduct
                                                                                }
                                                                                link={`/gallery/product-view/${data.id}`}
                                                                                handleClick={
                                                                                    handleClick
                                                                                }
                                                                                timerTheme=""
                                                                                sliderTheme="multiseller"
                                                                                cardTheme="liquidationTwo"
                                                                                bidBtnLabel="Place Bid"
                                                                                showCondition={
                                                                                    false
                                                                                }
                                                                                showLocation={true}
                                                                                timerIcon={true}
                                                                                bidStatus={true}
                                                                                setViewProduct={
                                                                                    setViewProduct
                                                                                }
                                                                                viewProduct={
                                                                                    viewProduct
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="lotResults mb-4 liquidationBuyCard">
                                                                            <GridProduct
                                                                                data={data}
                                                                                className={
                                                                                    data.auction
                                                                                        ? 'auction'
                                                                                        : 'buynow'
                                                                                }
                                                                                key={index}
                                                                                type={
                                                                                    data.auction
                                                                                        ? 'auction'
                                                                                        : 'buynow'
                                                                                }
                                                                                selectedProduct={
                                                                                    selectedProduct
                                                                                }
                                                                                link={`/gallery/product-view/${data.id}`}
                                                                                handleClick={
                                                                                    handleClick
                                                                                }
                                                                                timerTheme=""
                                                                                sliderTheme="multiseller"
                                                                                cardTheme="liquidationTwo"
                                                                                bidBtnLabel="Place Bid"
                                                                                showCondition={
                                                                                    false
                                                                                }
                                                                                showLocation={true}
                                                                                timerIcon={true}
                                                                                bidStatus={true}
                                                                                setViewProduct={
                                                                                    setViewProduct
                                                                                }
                                                                                viewProduct={
                                                                                    viewProduct
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )
                                                        }
                                                    })}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="spellbindingDeals position-relative">
                                    <h3 className="todayDealsTit">
                                        Today&apos;s Spellbinding Deals
                                    </h3>
                                    <div className="searchHomeContainer wizardSearchHomeContainer">
                                        {viewProduct
                                            ?.filter((ele) => ele.auction == 1)
                                            ?.map((data, index) => {
                                                if (index < 28) {
                                                    return (
                                                        <>
                                                            {!checkMediaWithPrimeBidTheme ? (
                                                                <div className="homeGridCard">
                                                                    <GridProduct
                                                                        data={data}
                                                                        className={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        key={index}
                                                                        type={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        selectedProduct={
                                                                            selectedProduct
                                                                        }
                                                                        link={`/gallery/product-view/${data.id}`}
                                                                        handleClick={handleClick}
                                                                        timerTheme=""
                                                                        sliderTheme="multiseller"
                                                                        cardTheme="liquidationTwo"
                                                                        bidBtnLabel="Bid Now"
                                                                        showCondition={false}
                                                                        showLocation={true}
                                                                        timerIcon={true}
                                                                        bidStatus={true}
                                                                        setViewProduct={
                                                                            setViewProduct
                                                                        }
                                                                        viewProduct={viewProduct}
                                                                        showDateNumber={2}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div className="lotResults mb-4 liquidationBuyCard">
                                                                    <GridProduct
                                                                        data={data}
                                                                        className={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        key={index}
                                                                        type={
                                                                            data.auction
                                                                                ? 'auction'
                                                                                : 'buynow'
                                                                        }
                                                                        selectedProduct={
                                                                            selectedProduct
                                                                        }
                                                                        link={`/gallery/product-view/${data.id}`}
                                                                        handleClick={handleClick}
                                                                        timerTheme=""
                                                                        sliderTheme="multiseller"
                                                                        cardTheme="liquidationTwo"
                                                                        bidBtnLabel="Bid Now"
                                                                        showCondition={false}
                                                                        showLocation={true}
                                                                        timerIcon={true}
                                                                        bidStatus={true}
                                                                        setViewProduct={
                                                                            setViewProduct
                                                                        }
                                                                        viewProduct={viewProduct}
                                                                        showDateNumber={2}
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            })}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="customContainer auctions">
                        <div className="auctionEndingSoon position-relative">
                            <h3 className="todayDealsTit">Auction Ending Soon</h3>

                            {viewAuction.length ? (
                                <>
                                    {isWizardTheme ? (
                                        <div className="slider-container" ref={containerRef}>
                                            <Slider {...settingsWizard}>
                                                {viewAuction.map((data, index) => (
                                                    <MultisellerListCard
                                                        data={data}
                                                        key={index}
                                                        details={`search/product-buyer-auction/${data.id}`}
                                                        card_theme="liquidationTwo"
                                                        handleLotClick={handleClick}
                                                    />
                                                ))}
                                            </Slider>
                                        </div>
                                    ) : (
                                        viewAuction.map((data, index) => (
                                            <MultisellerListCard
                                                data={data}
                                                key={index}
                                                details={`search/product-buyer-auction/${data.id}`}
                                                card_theme="liquidationTwo"
                                                handleLotClick={handleClick}
                                            />
                                        ))
                                    )}
                                </>
                            ) : (
                                <NoRecordsFound />
                            )}
                        </div>
                    </div>
                    <div className="customContainer">
                        <div className="locations position-relative">
                            <h3 className="todayDealsTit">Locations</h3>
                            <div className="wizardLocations">
                                {allLocations?.length > 0 ? (
                                    <>
                                        {allLocations.map((val) => (
                                            <WorkingHours locations={val} />
                                        ))}
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>

                    {!isWizardTheme && !LiquidityProject ? (
                        <>
                            {checkThemeClassName || !checkEmursTheme ? (
                                <div className="customContainer">
                                    <div className="pmEllipseContainer d-flex align-items-center pt-5 pb-5">
                                        <div className="PmEllipse">
                                            <span className="material-icons-outlined">info</span>
                                        </div>
                                        <h3>Learn more</h3>
                                    </div>

                                    <div className="info-section">
                                        {/* Left Column */}
                                        <div className="info-text">
                                            <h2>How it works</h2>
                                            <div className="info-details">
                                                <div className="info-row">
                                                    <span className="info-title">
                                                        Win your item:
                                                    </span>
                                                    <span className="info-description">
                                                        Bid, win, and pay for your items.
                                                    </span>
                                                </div>
                                                <div className="info-row">
                                                    <span className="info-title">
                                                        Get notified:
                                                    </span>
                                                    <span className="info-description">
                                                        Notifications will come through email and in
                                                        your account once items are prepared for
                                                        pickup. <em>(No walk-ins)</em>
                                                    </span>
                                                </div>
                                                <div className="info-row">
                                                    <span className="info-title">Pick up:</span>
                                                    <span className="info-description">
                                                        You get 5 days from the point of
                                                        notification to pick up your items.{' '}
                                                        <em>Please ensure prompt payment</em>
                                                    </span>
                                                </div>
                                            </div>

                                            <h2>What’s in it for you?</h2>
                                            <div className="benefits">
                                                <div className="benefit-card">
                                                    <h4>
                                                        Less <br />
                                                        waiting
                                                    </h4>
                                                    <p>Items will be ready when you arrive.</p>
                                                </div>
                                                <div className="benefit-card">
                                                    <h4>
                                                        Clear <br />
                                                        communication
                                                    </h4>
                                                    <p>You’ll know when the items are ready.</p>
                                                </div>
                                                <div className="benefit-card">
                                                    <h4>
                                                        Improved <br />
                                                        efficiency
                                                    </h4>
                                                    <p>A smoother pickup process for everyone.</p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Right Column */}
                                        <div className="info-video">
                                            <h2>Learn more about what we do</h2>
                                            <div className="video-placeholder">
                                                {' '}
                                                <video width={350} height={320} controls>
                                                    <source
                                                        src="/assets/video/demoPrimebids.mp4"
                                                        type="video/mp4"
                                                    ></source>
                                                </video>
                                            </div>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/how_it_works"
                                                    className=""
                                                >
                                                    {' '}
                                                    Visit{' '}
                                                    <span className="hiwLink">
                                                        how it works
                                                    </span>{' '}
                                                    for more information about what we do.
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="31"
                                                        height="30"
                                                        viewBox="0 0 31 30"
                                                        fill="none"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M16.8238 8.49582C17.0816 8.23802 17.4996 8.23802 17.7574 8.49582L23.7325 14.4709C23.9903 14.7287 23.9903 15.1467 23.7325 15.4045L17.7574 21.3796C17.4996 21.6374 17.0816 21.6374 16.8238 21.3796C16.566 21.1217 16.566 20.7038 16.8238 20.4459L21.6719 15.5979H8.32806C7.96346 15.5979 7.6679 15.3023 7.6679 14.9377C7.6679 14.5731 7.96346 14.2775 8.32806 14.2775L21.6719 14.2775L16.8238 9.42943C16.566 9.17162 16.566 8.75363 16.8238 8.49582Z"
                                                            fill="#222222"
                                                        />
                                                    </svg>
                                                </NavLink>{' '}
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : checkEmursTheme ? null : (
                                <div className="hiwContainer">
                                    <h2 className="divHdrTxt text-center">
                                        {checkBeachThemeClassName ? 'Why Choose Us' : 'What we do'}
                                    </h2>
                                    {checkBeachThemeClassName ? (
                                        <>
                                            <div className="customContainer whyUsBeach">
                                                <div>
                                                    <img src="/assets/images/Why_us_page_image.png" />
                                                    <ul>
                                                        <li>
                                                            <b>Unrivaled Product Range: </b>From
                                                            single items to bulk lots, we&apos;ve
                                                            got it all. Need one item or a hundred?
                                                            We&apos;ve got you covered!
                                                        </li>
                                                        <li>
                                                            <b>Flexible Buying Options: </b>Shop
                                                            online or in-person - the choice is
                                                            yours!
                                                        </li>
                                                        <li>
                                                            <b>Transparent Pricing: </b>No hidden
                                                            fees, no surprises. Just honest,
                                                            competitive prices.
                                                        </li>
                                                        <li>
                                                            <b>Immediate Pick-up: </b>Why wait? Get
                                                            your items right away!
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="customContainer whtWeDo">
                                            <div className="PmHiwBase-root">
                                                <div className="PmHiwVideoDetails-root">
                                                    <ul>
                                                        <li>
                                                            {global?.storeDetails?.site_id ===
                                                            'AS0698'
                                                                ? 'At PRIME BIDS, '
                                                                : ' '}
                                                            We buy returned and overstocked goods
                                                            from top retailers and sell them in
                                                            online auctions.
                                                        </li>
                                                        <li>
                                                            Some items start at $1, while others
                                                            begin with discounts of 5-15% off retail
                                                            prices.
                                                        </li>
                                                        <li>
                                                            We have over 10,000 winners each WEEK.
                                                        </li>
                                                        <li>
                                                            Did you win? Schedule a pick up
                                                            appointment so your items are ready to
                                                            go.
                                                        </li>
                                                        <li>
                                                            Learn how to Bid, Win & Save up to 80%
                                                            off retail prices in this video also
                                                            visit our
                                                            <NavLink
                                                                activeClassName="active"
                                                                to="/how_it_works"
                                                            >
                                                                {' '}
                                                                How it works
                                                            </NavLink>{' '}
                                                            for more details
                                                        </li>
                                                    </ul>
                                                    {/* <a href="#">Read Our FAQ&apos;s to Learn More</a> */}
                                                </div>
                                                <div className="PmHiwVideo-root">
                                                    <video width={350} height={320} controls>
                                                        <source
                                                            src="/assets/video/demoPrimebids.mp4"
                                                            type="video/mp4"
                                                        ></source>
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="splEvntsWrpr">
                                <div className="customContainer pb-4">
                                    {checkThemeClassName || checkEmursTheme ? (
                                        <div className={checkEmursTheme ? 'seperator' : null}>
                                            {' '}
                                            <div className="pmEllipseContainer d-flex align-items-center pt-5 pb-5">
                                                <div className="PmEllipse">
                                                    <span className="material-icons-outlined">
                                                        priority_high
                                                    </span>
                                                </div>
                                                <h3>Special Events</h3>
                                            </div>
                                            <div className="vwActnWrapper">
                                                {viewAuction.length ? (
                                                    <div className="hmActnLstWrpr">
                                                        {viewAuction.map((data, index) => (
                                                            <MultisellerListCard
                                                                data={data}
                                                                key={index}
                                                                details={`search/product-buyer-auction/${data.id}`}
                                                                card_theme="liquidationTwo"
                                                                handleLotClick={handleClick}
                                                            />
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <NoRecordsFound />
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {' '}
                                            <h2 className="divHdrTxt text-center">
                                                SPECIAL EVENTS
                                            </h2>
                                            <div className="vwActnWrapper">
                                                {viewAuction.length ? (
                                                    <div className="hmActnLstWrpr">
                                                        {viewAuction.map((data, index) => (
                                                            <MultisellerListCard
                                                                data={data}
                                                                key={index}
                                                                details={`search/product-buyer-auction/${data.id}`}
                                                                card_theme="liquidationTwo"
                                                            />
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <NoRecordsFound />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {checkEmursTheme || checkThemeClassName ? null : (
                                <div className="splEvntsWrpr">
                                    <div className="customContainer pb-4">
                                        <h2 className="divHdrTxt text-center">SPECIAL EVENTS</h2>
                                        <div className="vwActnWrapper">
                                            {viewAuction.length ? (
                                                <div className="hmActnLstWrpr">
                                                    {viewAuction.map((data, index) => (
                                                        <MultisellerListCard
                                                            data={data}
                                                            key={index}
                                                            details={`search/product-buyer-auction/${data.id}`}
                                                            card_theme="liquidationTwo"
                                                        />
                                                    ))}
                                                </div>
                                            ) : (
                                                <NoRecordsFound />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {global?.pluginConfiguration?.google_reviews?.enable == 1 ? (
                                <div className="googleReviewPoint">
                                    <div className="customContainer pb-4">
                                        <h3>Our Reviews</h3>
                                        {checkThemeClassName ? (
                                            <ReactGoogleReviews
                                                layout="carousel"
                                                featurableId={
                                                    process.env.REACT_APP_FEATURABLE_PLUGIN_ID
                                                }
                                            />
                                        ) : (
                                            <Reviews />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                            {global?.storeConfigration?.fact_to_know?.value == 0 ||
                            checkEmursTheme ? null : (
                                <>
                                    {global?.storeDetails?.fact_to_know ? (
                                        <div className="faqContainer">
                                            <div className="customContainer py-4">
                                                <h2 className="divHdrTxt">
                                                    Facts to know{' '}
                                                    <Link
                                                        to="/faq"
                                                        className="extnlLnk cursorDecoy"
                                                    >
                                                        VIEW FAQS
                                                    </Link>
                                                </h2>
                                                <div className="fqLst">
                                                    {' '}
                                                    {ReactHtmlParser(
                                                        global.storeDetails.fact_to_know,
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="faqContainer">
                                            <div className="customContainer py-4">
                                                <h2 className="divHdrTxt">
                                                    Facts to know{' '}
                                                    <Link
                                                        to="/faq"
                                                        className="extnlLnk cursorDecoy"
                                                    >
                                                        VIEW FAQS
                                                    </Link>
                                                </h2>
                                                <div className="fqLst">
                                                    <ul>
                                                        <li>
                                                            Most items have a starting bid of $5
                                                        </li>
                                                        <li>
                                                            Prices increase by our bid increments as
                                                            bids are placed
                                                        </li>
                                                        <li>
                                                            We’ll notify you via text and/or email
                                                            if you’ve been outbid
                                                        </li>
                                                        <li>
                                                            Credit cards are charged automatically
                                                            for any winnings at the end of the
                                                            auction
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            You have 7 days for local pickup item
                                                            retrieval
                                                        </li>
                                                        <li>
                                                            We offer Refunds, please view our Policy
                                                            for more details
                                                        </li>
                                                        <li>
                                                            An 11% Buyer’s Premium applies to all
                                                            sales
                                                        </li>
                                                        <li>
                                                            We collect Sales Tax unless you provide
                                                            a valid California Resale License
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="hiwContainer">
                                        <h2 className="divHdrTxt text-center">
                                            {checkBeachThemeClassName
                                                ? 'Why Choose Us'
                                                : 'What we do'}
                                        </h2>
                                        {checkBeachThemeClassName ? (
                                            <>
                                                <div className="customContainer whyUsBeach">
                                                    <div>
                                                        <img src="/assets/images/Why_us_page_image.png" />
                                                        <ul>
                                                            <li>
                                                                <b>Unrivaled Product Range: </b>From
                                                                single items to bulk lots,
                                                                we&apos;ve got it all. Need one item
                                                                or a hundred? We&apos;ve got you
                                                                covered!
                                                            </li>
                                                            <li>
                                                                <b>Flexible Buying Options: </b>Shop
                                                                online or in-person - the choice is
                                                                yours!
                                                            </li>
                                                            <li>
                                                                <b>Transparent Pricing: </b>No
                                                                hidden fees, no surprises. Just
                                                                honest, competitive prices.
                                                            </li>
                                                            <li>
                                                                <b>Immediate Pick-up: </b>Why wait?
                                                                Get your items right away!
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="customContainer whtWeDo">
                                                <div className="PmHiwBase-root">
                                                    <div className="PmHiwVideoDetails-root">
                                                        <ul>
                                                            <li>
                                                                {global?.storeDetails?.site_id ===
                                                                'AS0698'
                                                                    ? 'At PRIME BIDS, '
                                                                    : ' '}
                                                                We buy returned and overstocked
                                                                goods from top retailers and sell
                                                                them in online auctions.
                                                            </li>
                                                            <li>
                                                                Some items start at $1, while others
                                                                begin with discounts of 5-15% off
                                                                retail prices.
                                                            </li>
                                                            <li>
                                                                We have over 10,000 winners each
                                                                WEEK.
                                                            </li>
                                                            <li>
                                                                Did you win? Schedule a pick up
                                                                appointment so your items are ready
                                                                to go.
                                                            </li>
                                                            <li>
                                                                Learn how to Bid, Win & Save up to
                                                                80% off retail prices in this video
                                                                also visit our
                                                                <NavLink
                                                                    activeClassName="active"
                                                                    to="/how_it_works"
                                                                >
                                                                    {' '}
                                                                    How it works
                                                                </NavLink>{' '}
                                                                for more details
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="PmHiwVideo-root">
                                                        <video width={350} height={320} controls>
                                                            <source
                                                                src="/assets/video/demoPrimebids.mp4"
                                                                type="video/mp4"
                                                            ></source>
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="splEvntsWrpr">
                                        <div className="customContainer pb-4">
                                            <h2 className="divHdrTxt text-center">
                                                SPECIAL EVENTS
                                            </h2>
                                            <div className="vwActnWrapper">
                                                {viewAuction.length ? (
                                                    <div className="hmActnLstWrpr">
                                                        {viewAuction.map((data, index) => (
                                                            <MultisellerListCard
                                                                data={data}
                                                                key={index}
                                                                details={`search/product-buyer-auction/${data.id}`}
                                                                card_theme="liquidationTwo"
                                                            />
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <NoRecordsFound />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {global?.pluginConfiguration?.google_reviews?.enable == 1 ? (
                                        <div className="googleReviewPoint">
                                            <div className="customContainer pb-4">
                                                <h3>Our Reviews</h3>

                                                <Reviews />
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {global?.storeConfigration?.fact_to_know?.value == 0 ? null : (
                                        <>
                                            {' '}
                                            {global?.storeDetails?.fact_to_know ? (
                                                <div className="faqContainer">
                                                    <div className="customContainer py-4">
                                                        <h2 className="divHdrTxt">
                                                            Facts to know{' '}
                                                            <Link
                                                                to="/faq"
                                                                className="extnlLnk cursorDecoy"
                                                            >
                                                                VIEW FAQS
                                                            </Link>
                                                        </h2>
                                                        <div className="fqLst">
                                                            {' '}
                                                            {ReactHtmlParser(
                                                                global.storeDetails.fact_to_know,
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="faqContainer">
                                                    <div className="customContainer py-4">
                                                        <h2 className="divHdrTxt">
                                                            Facts to know{' '}
                                                            <Link
                                                                to="/faq"
                                                                className="extnlLnk cursorDecoy"
                                                            >
                                                                VIEW FAQS
                                                            </Link>
                                                        </h2>
                                                        <div className="fqLst">
                                                            <ul>
                                                                <li>
                                                                    Most items have a starting bid
                                                                    of $5
                                                                </li>
                                                                <li>
                                                                    Prices increase by our bid
                                                                    increments as bids are placed
                                                                </li>
                                                                <li>
                                                                    We’ll notify you via text and/or
                                                                    email if you’ve been outbid
                                                                </li>
                                                                <li>
                                                                    Credit cards are charged
                                                                    automatically for any winnings
                                                                    at the end of the auction
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    You have 7 days for local pickup
                                                                    item retrieval
                                                                </li>
                                                                <li>
                                                                    We offer Refunds, please view
                                                                    our Policy for more details
                                                                </li>
                                                                <li>
                                                                    An 11% Buyer’s Premium applies
                                                                    to all sales
                                                                </li>
                                                                <li>
                                                                    We collect Sales Tax unless you
                                                                    provide a valid California
                                                                    Resale License
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {global?.storeConfigration?.subscribe?.value && (
                                                <div className="homeContact">
                                                    <h2>Get the latest deals</h2>
                                                    <p>Subscribe us to get latest updates</p>
                                                    <div className="subsBox">
                                                        <input
                                                            type="text"
                                                            name="q"
                                                            value={inpData}
                                                            placeholder="Enter email address"
                                                            onChange={(e) => {
                                                                setinpData(e.target.value)
                                                            }}
                                                        />
                                                        <Button>
                                                            <span
                                                                className="material-icons"
                                                                onClick={subscribe}
                                                            >
                                                                send
                                                            </span>
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    ) : null}
                    {LiquidityProject ? (
                        <>
                            <div className="splEvntsWrpr">
                                <div className="customContainer pb-4">
                                    <div>
                                        {' '}
                                        <h2 className="divHdrTxt text-center">SPECIAL EVENTS</h2>
                                        <div className="vwActnWrapper">
                                            {viewAuction.length ? (
                                                <div className="hmActnLstWrpr">
                                                    {viewAuction.map((data, index) => (
                                                        <MultisellerListCard
                                                            data={data}
                                                            key={index}
                                                            details={`search/product-buyer-auction/${data.id}`}
                                                            card_theme="liquidationTwo"
                                                        />
                                                    ))}
                                                </div>
                                            ) : (
                                                <NoRecordsFound />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="faqContainer">
                                <div className="customContainer py-4">
                                    <h2 className="divHdrTxt">
                                        Facts to know{' '}
                                        <Link to="/faq" className="extnlLnk cursorDecoy">
                                            VIEW FAQS
                                        </Link>
                                    </h2>
                                    <div className="fqLst">
                                        <ul>
                                            <li>Most items have a starting bid of $5</li>
                                            <li>
                                                Prices increase by our bid increments as bids are
                                                placed
                                            </li>
                                            <li>
                                                We’ll notify you via text and/or email if you’ve
                                                been outbid
                                            </li>
                                            <li>
                                                Credit cards are charged automatically for any
                                                winnings at the end of the auction
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>You have 7 days for local pickup item retrieval</li>
                                            <li>
                                                We offer Refunds, please view our Policy for more
                                                details
                                            </li>
                                            <li>An 11% Buyer’s Premium applies to all sales</li>
                                            <li>
                                                We collect Sales Tax unless you provide a valid
                                                California Resale License
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </>
            </div>
            <MultisellerSlider
                selectedLot={selectedProduct}
                handleClose={handleClose}
                currency="cad"
                type={props.type ? props.type : ''}
                storeTheme="Liquidation_two"
                pvTabList={true}
            />
        </Layout>
    )
}

export default Home
